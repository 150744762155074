<template>
  <div>跳转中</div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['menuList', 'menuItems']),
  },

  created() {
    const menu = _.find(this.menuList, { type: 2 });
    menu && this.$router.replace(menu.path)
  }

}
</script>